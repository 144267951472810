import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "datatables.net-bs4"

Rails.start()
ActiveStorage.start()

import 'bootstrap'

function requireAll(r) { r.keys().forEach(r) }
requireAll(require.context('./behaviors/', true, /\.js$/))

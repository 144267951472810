$(document).on("update ready show.bs.modal", function () {
  const $this = $("#dataTables");
  $.fn.dataTable.moment("MMMM DD, YYYY HH:mm");
  const table = $this.DataTable({
    retrieve: true,
    pageLength: 25,
    fixedHeader: {
      header: true,
      footer: true,
    },
    info: true,
    lengthChange: false,
    searching: true,
    ordering: true,
    language: {
      search: "_INPUT_",
      searchPlaceholder: "Search...",
    },
  });
  $(".dataTables_filter").addClass("float-md-right");
  $("#dataTables_wrapper").addClass("mt-4");
});
